<template>
    <div class="overall-page">
        <div class="head">
            <Breadcrumb :routerList="routerList"></Breadcrumb>
            <div class="head-left">
                <i class="el-icon-arrow-left" @click="back"></i>
                <span class="head-title">词库操作历史</span>
            </div>
        </div>
        <div class="page">
            <el-card shadow="never">
                <el-table :data="wordsHistoryData" stripe :header-cell-style="headerCellStyle"
                    :cell-style="{ 'text-align': 'center' }" style="
            width: 100%;
            text-align: center;
            border: 0.0625rem solid #e4e6ec;
            border-bottom: none;
          ">
                    <el-table-column prop="createUser" label="操作人"></el-table-column>
                    <el-table-column label="操作时间">
                        <template slot-scope="scope">
                            {{ getDate(scope.row.createTime) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.logType === 1">添加</span>
                            <span v-if="scope.row.logType === 3">删除</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作详情">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleClick(scope.row)">
                                查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <Pagination :pageNumber="page.pageNumber" :pageSize="page.pageSize" :total="total"
                    @SizeChange="sizeChange" @CurrentChange="currentChange"></Pagination>
            </el-card>
        </div>
        <el-drawer title="操作详情" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
            <div v-for="(item, index) in detailData" :key="index">
                <el-divider></el-divider>
                <div class="pd-20">
                    <div class="d-flex mb-10" v-if="item.title == '添加成功的词'">
                        <div class="status-icon success">
                            <i class="el-icon-check"></i>
                        </div>
                        {{ item.title }}
                    </div>
                    <div class="d-flex mb-10" v-if="item.title != '添加成功的词'">
                        <div class="status-icon error">
                            <i class="el-icon-close"></i>
                        </div>
                        {{ item.title }}
                    </div>
                    <div v-for="(subItem, i) in item.data" :key="i" class="item-text">
                        <div>
                            <span>-</span><span class="bold">正确词：</span><span>{{ subItem.correctWord }}</span>
                        </div>
                        <div>
                            <span>-</span><span class="bold">错误词：</span><span>{{ subItem.errorWord }}</span>

                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import Pagination from "@/components/pagination.vue";
import { orgWordLogList, orgWordLogDetail } from "@/api/myWords";

export default {
    components: { Breadcrumb, Pagination },
    data() {
        return {
            routerList: ["我的词库", "词库操作历史"],
            wordsHistoryData: [],
            detailData: [],
            page: {
                pageNumber: 1,
                pageSize: 10,
            }, //分页数据
            total: 0,
            drawer: false,
            direction: "rtl", // 定义 direction 属性
        };
    },
    computed: {
        headerCellStyle() {
            return {
                "text-align": "center",
                background: "#F6F7FA",
                color: "#333",
            };
        },
    },
    mounted() {
        this.getWordsHistoryData();
    },
    methods: {
        //  格式化日期
        getDate(date) {
            return this.$main.timeCycle(date, "yyyy-MM-dd HH:mm:ss");
        },
        //  获取词库操作历史
        getWordsHistoryData() {
            const params = {
                limit: this.page.pageSize,
                page: this.page.pageNumber,
            };
            orgWordLogList(params)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.wordsHistoryData = res.data.data.list;
                        this.total = Number(res.data.data.totalCount);
                    }
                })
                .catch((err) => { })
                .finally(() => { });
        },
        //  点击查看详情
        handleClick(item) {
            this.drawer = true;
            const params = {
                id: item.id,
            };
            orgWordLogDetail(params)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.detailData = JSON.parse(res.data.data.message);
                    }
                })
                .catch((err) => { })
                .finally(() => { });
        },
        //  返回
        back() {
            this.$router.push({ path: "/settings" });
        },
        //  分页
        sizeChange(value) {
            this.page.pageSize = value;
            this.getWordsHistoryData();
        },
        //  页码
        currentChange(value) {
            this.page.pageNumber = value;
            this.getWordsHistoryData();
        },
        //  关闭抽屉
        handleClose() {
            // 在关闭抽屉时，可以进行一些清理工作，比如重置状态等
            this.drawer = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.head {
    padding: 20px 20px 0 20px;

    .head-left {
        margin-top: 20px;

        i {
            cursor: pointer;
            font-size: 14px;
            color: #1a1a1a;
        }

        .head-title {
            font-weight: 600;
            font-size: 20px;
            color: #1a1a1a;
            line-height: 28px;
            text-align: left;
            margin-left: 10px;
        }
    }
}

.pd-20 {
    padding: 0 20px;
}

.status-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 10px;
}

.status-icon.success {
    background-color: #67c23a;
    color: white;
}

.status-icon.error {
    background-color: #f56c6c;
    color: white;
}

.status-icon i {
    font-size: 12px;
}

.d-flex {
    display: flex;
    align-items: center;
}

.mb-10 {
    margin-bottom: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.item-text {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.bold {
    font-weight: bold;
}
</style>